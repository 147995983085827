


























import { Component, Vue } from "vue-property-decorator";
import { firestore } from "../firebaseInit";

import { doc, getDoc, setDoc } from "firebase/firestore";

@Component({ components: {} })
export default class SoldArt extends Vue {
  private about: string = "";
  private contact: string = "";
  private contentDoc = doc(firestore, "page-content", "SSLO9vxO73B5pvjLeUAG");

  async created() {
    const docSnap = await getDoc(this.contentDoc);

    if (docSnap.exists()) {
      const content: any = docSnap.data();
      this.about = content.about;
      this.contact = content.contact;
    }
  }

  async submit() {
    await setDoc(this.contentDoc, {
      about: this.about,
      contact: this.contact,
    }).catch(() => {
      alert("There was an issue submitting the data, please try again.");
    });

    alert("Successfully submitted changes!");
  }
}
