












import { Component, Vue } from "vue-property-decorator";
import Home from "./components/pages/Home.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

@Component({
  components: { Home, Header, Footer },
})
export default class App extends Vue {}
