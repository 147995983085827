






















import { Component, Vue, Prop } from "vue-property-decorator";
import Thumbnail from "./Thumbnail.vue";
import { Art } from "@/models/Art";

@Component({
  components: {
    Thumbnail,
  },
})
export default class ArtShowcase extends Vue {
  @Prop({ required: true }) readonly artArray!: Art[];
  private pageHeight = 0; // The mortar-style layout requires fixed height. This adjusts dynamically;
  private loadCount = 0;

  created() {
    window.addEventListener("resize", this.setPageHeight);
  }

  destroyed() {
    window.removeEventListener("resize", this.setPageHeight);
  }

  setPageHeight() {
    this.pageHeight = Number.MAX_VALUE;
    setTimeout(() => {
      const columnHeightArray: number[] = [0, 0, 0];
      let columnCounter = 0;
      this.artArray.forEach((art) => {
        const element = document.getElementById(`${art.id} thumbnail`);
        columnHeightArray[columnCounter] +=
          (element?.offsetHeight || 500) * 1.06 + 15;
        columnCounter++;
        if (columnCounter > 2) columnCounter = 0;
      });

      const tallestColumnHeight = Math.max(...columnHeightArray);
      this.pageHeight = tallestColumnHeight;
    });
  }

  get breakArray() {
    const array = this.artArray.map((art, index) => {
      return index;
    });

    return array;
  }
}
