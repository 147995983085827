



























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { firestore, storage } from "../firebaseInit";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, getStorage, uploadBytes } from "firebase/storage";
import { Art } from "../models/Art";

@Component({ components: {} })
export default class EditReproductions extends Vue {
  private readonly storage = getStorage();
  private readonly FileUploadStatus = Object.freeze({
    INITIAL: "INITIAL",
    SAVING: "SAVING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
  });

  private artArray: Art[] = [];
  private selectedArtId: string = "";
  private selectedImageUrl: string = "";
  private showSuccessModal: boolean = true;
  private fileUploadStatus = this.FileUploadStatus.INITIAL;
  private fileToUpload: File | null = null;

  get selectedArt() {
    return this.artArray.find((art) => {
      return art.id === this.selectedArtId;
    });
  }

  get fileSize() {
    if (!this.fileToUpload) return 0;

    const sizeInMB = this.fileToUpload.size / (1024 * 1024);
    if (sizeInMB > 1) return sizeInMB.toFixed(2) + " MB";

    const sizeInKB = this.fileToUpload.size / 1024;

    return sizeInKB.toFixed(0) + " KB";
  }

  get fileSizeInKB() {
    if (!this.fileToUpload) return 0;
    else return (this.fileToUpload.size / 1024).toFixed(0);
  }

  async created() {
    await this.getArtFromDb();
    if (this.artArray.length > 0) this.selectedArtId = this.artArray[0].id;
  }

  async getArtFromDb() {
    const artArray: Art[] = [];
    const imagesRef = collection(firestore, "reproductions");
    const collectionSnap = await getDocs(imagesRef);

    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id };
      artArray.push(art as Art);
    });

    this.artArray = artArray;
  }

  async deleteArt() {
    const confirmed = confirm("Are you sure you want to delete this?");
    if (confirmed) {
      const documentToDelete = doc(
        firestore,
        "reproductions",
        this.selectedArtId
      );
      await deleteDoc(documentToDelete).catch(() => {
        alert("Error deleting art, please try again.");
        return;
      });

      alert("Successfully deleted art!");
      await this.getArtFromDb();
      if (this.artArray.length > 0) this.selectedArtId = this.artArray[0].id;
    }
  }

  async updateArt() {
    const documentToUpdate = doc(firestore, "reproductions", this.selectedArtId);
    const updatePromises: Promise<any>[] = [];

    updatePromises.push(setDoc(documentToUpdate, this.selectedArt));
    if (this.fileToUpload) {
      this.fileUploadStatus = this.FileUploadStatus.SAVING;
      const storageRef = ref(storage, this.selectedArt?.storageLocation);
      updatePromises.push(uploadBytes(storageRef, this.fileToUpload));
    }

    await Promise.all(updatePromises).catch(() => {
      alert("Error updating, please try again.");
      return;
    });
    alert("Successfully updated!");
    await this.getArtFromDb();

    this.fileUploadStatus = this.FileUploadStatus.INITIAL;
    this.fileToUpload = null;
  }

  filesChange(fileList: any[]) {
    if (!fileList.length) return;
    else this.fileToUpload = fileList[0];
  }

  @Watch(`selectedArt`)
  async getSelectedImageUrl() {
    if (!this.selectedArt) return "";

    const path = ref(storage, this.selectedArt.storageLocation);
    const url = await getDownloadURL(path);
    this.selectedImageUrl = url;
  }
}
