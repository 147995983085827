

























































































import { Component, Vue } from "vue-property-decorator";
import { firestore, storage } from "../firebaseInit";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { ref, getStorage, uploadBytes } from "firebase/storage";
import { Art } from "../models/Art";

@Component({ components: {} })
export default class UploadArt extends Vue {
  private readonly storage = getStorage();
  private readonly FileUploadStatus = Object.freeze({
    INITIAL: "INITIAL",
    SAVING: "SAVING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
  });

  private artArray: Art[] = [];
  private selectedImageUrl: string = "";
  private showSuccessModal: boolean = true;
  private isGalleryUpload: boolean = true;
  private isReproductionsUpload: boolean = false;
  private fileUploadStatus: any = this.FileUploadStatus.INITIAL;
  private fileToUpload: File | null = null;
  private name: string = "";
  private description: string = "";
  private price: number = 100;
  private reproductionType: 'card' | 'print' = 'card';

  get fileSize() {
    if (!this.fileToUpload) return 0;

    const sizeInMB = this.fileToUpload.size / (1024 * 1024);
    if (sizeInMB > 1) return sizeInMB.toFixed(2) + " MB";

    const sizeInKB = this.fileToUpload.size / 1024;

    return sizeInKB.toFixed(0) + " KB";
  }

  get fileSizeInKB() {
    if (!this.fileToUpload) return 0;
    else return (this.fileToUpload.size / 1024).toFixed(0);
  }

  async created() {
    await this.getArtFromDb();
  }

  async getArtFromDb() {
    this.artArray = [];
    const imagesRef = collection(firestore, "home-images");
    const collectionSnap = await getDocs(imagesRef);

    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id };
      this.artArray.push(art as Art);
    });
  }

  async uploadArt() {
    if (
      !(
        this.name &&
        this.description &&
        this.price !== undefined &&
        this.fileToUpload
      )
    ) {
      alert("Please fill out all fields before submitting.");
      return;
    }

    if (!this.isGalleryUpload && !this.isReproductionsUpload) {
      alert("Please select an Art Type (Gallery or Reproductions) before submitting.");
      return;
    }

    const galleryCollection = collection(firestore, "home-images");
    const reproductionsCollection = collection(firestore, "reproductions");
    const updatePromises: Promise<any>[] = [];
    const galleryDocument: any = {
      name: this.name,
      description: this.description,
      price: this.price,
      createdDate: new Date().toLocaleString(),
      storageLocation: `${this.name}-${new Date().toISOString()}`,
    };

    const reproductionDocument = {...galleryDocument, isReproduction: true, reproductionType: this.reproductionType} 

    if (this.isGalleryUpload) updatePromises.push(addDoc(galleryCollection, galleryDocument));
    if (this.isReproductionsUpload) updatePromises.push(addDoc(reproductionsCollection, reproductionDocument));
    this.fileUploadStatus = this.FileUploadStatus.SAVING;
    const storageRef = ref(storage, galleryDocument.storageLocation);
    updatePromises.push(uploadBytes(storageRef, this.fileToUpload));

    await Promise.all(updatePromises).catch(() => {
      alert("Error uploading art, please try again.");
      return;
    });
    alert("Successfully uploaded art!");
    await this.getArtFromDb();

    this.fileUploadStatus = this.FileUploadStatus.INITIAL;
    this.fileToUpload = null;
    this.name = "";
    this.description = "";
    this.price = 100;
  }

  filesChange(fileList: any[]) {
    if (!fileList.length) return;
    else this.fileToUpload = fileList[0];
  }
}
