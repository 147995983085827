













import { Component, Vue, Prop } from "vue-property-decorator";
import { Art } from "@/models/Art";
import { storage } from "../firebaseInit";
import { getDownloadURL, ref } from "firebase/storage";

@Component
export default class Thumbnail extends Vue {
  @Prop({ required: true }) readonly art!: Art;
  private url: string = "";
  private loading: boolean = true;
  private wrapperClass: string = "";

  async created() {
    const path = ref(storage, this.art.storageLocation);
    const url = await getDownloadURL(path);
    this.url = url;
  }

  get priceIsNan() {
    return isNaN(parseInt(this.art.price?.toString() ?? "", 10));
  }

  onImageLoad() {
    this.loading = false;
    this.wrapperClass = "border";
    this.$emit("imageLoad");
  }

  handleClickArt() {
    this.$router.push(`/art/${this.art.id}`);
  }
}
