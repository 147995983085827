











import { Component, Vue, Watch } from "vue-property-decorator";
import ArtShowcase from "../ArtShowcase.vue";
import { firestore } from "../../firebaseInit";
import { collection, getDocs } from "firebase/firestore";
import { Art } from "../../models/Art";

@Component({
  components: {
    ArtShowcase,
  },
})
export default class Reproductions extends Vue {
  private artArray: any[] = [];
  private categoryArtArray: any[] = [];
  private noArtTimeoutDone = false;
  private reproductionType: 'card' | 'print' = 'card'

  async created() {
    setTimeout(() => {
      this.noArtTimeoutDone = true;
    }, 500);

    const imagesRef = collection(firestore, "reproductions");
    const collectionSnap = await getDocs(imagesRef);

    let artArray: Art[] = [];
    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id } as Art;
      if (art.isActive !== false) artArray.push(art as Art);
    });

    let shuffle = (array: any[]): Art[] => {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    };

    this.artArray = [...shuffle(artArray)].slice(0, 100);
    this.categoryArtArray = this.artArray.filter(art => art.reproductionType == this.reproductionType);
  }

  @Watch('reproductionType')
  handleReproductionTypeChange() {
    this.categoryArtArray = this.artArray.filter(art => art.reproductionType == this.reproductionType);
  }
}
