






















































































import { Component, Vue } from "vue-property-decorator";
import { firestore } from "../../firebaseInit";
import { doc, getDoc } from "firebase/firestore";

@Component({ components: {} })
export default class Contact extends Vue {
  private name: string = "";
  private message: string = "";
  private contactText: string = "";
  private showContactModal: boolean = false;
  private hasAttemptedSubmit: boolean = false;
  private contentDoc = doc(firestore, "page-content", "SSLO9vxO73B5pvjLeUAG");

  async created() {
    const docSnap = await getDoc(this.contentDoc);

    if (docSnap.exists()) {
      const content: any = docSnap.data();
      this.contactText = content.contact;
    }
  }

  clearForm() {
    this.name = "";
    this.message = "";
  }

  handleSubmitForm() {
    this.hasAttemptedSubmit = true;

    const mailto = "mailto:jessietucker@gmail.com";
    const subject = `?subject=Jessie Tucker Art - Message from ${this.name}&`;
    const body = `body=${this.message}`;
    const email = mailto + subject + body;
    window.open(email, "_blank");
    (this.$refs.modalCloseButton as any)?.click();
    this.clearForm();
    this.hasAttemptedSubmit = false;
  }
}
