import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAFPQYxi5Bj_c95bHxaSYAOAVjeJsVbjYo",
  authDomain: "art-webapp-812f7.firebaseapp.com",
  projectId: "art-webapp-812f7",
  storageBucket: "art-webapp-812f7.appspot.com",
  messagingSenderId: "903572032631",
  appId: "1:903572032631:web:6e2d4ad18e2fb666f74a72",
  measurementId: "G-CCBEYV7FGB",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
