
















import { Component, Vue } from "vue-property-decorator";
import { firestore, storage } from "../../firebaseInit";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Art, SaleDetails } from "../../models/Art";
import { ref, getDownloadURL } from "firebase/storage";
import { loadStripe } from "@stripe/stripe-js";

@Component({ components: {} })
export default class PaymentConfirmed extends Vue {
  private art: Art | null = null;
  private artUrl: string = "";
  private paymentMessage: string = "";
  private paymentSucceeded: boolean = false;
  private stripe!: any;
  private shipmentDetails: {
    name: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  } = { name: "", email: "", street: "", city: "", state: "", zip: "" };

  async created() {
    this.stripe = await loadStripe(
      "pk_live_51L1gblGdMI5Rj1u0H4phwU7fOKvTN0Y3BtdW4kLtrMKxfu5XLlbcwj6E6aJhyesNaUMqSQjgxi2SoClJrkatMkcZ00EhfR0dCL"
    );

    const docRef = doc(firestore, "home-images", this.$route.params.id);
    const docSnap = await getDoc(docRef);
    const reproductionRef = doc(firestore, "reproductions", this.$route.params.id);
    const reproductionSnap = await getDoc(reproductionRef);

    if (docSnap.exists()) {
      this.art = docSnap.data() as Art;
      const path = ref(storage, this.art.storageLocation);
      this.artUrl = await getDownloadURL(path);
    } else if (reproductionSnap.exists()) {
      this.art = reproductionSnap.data() as Art;
      const path = ref(storage, this.art.storageLocation);
      this.artUrl = await getDownloadURL(path);
    }

    this.checkStatusAndUpdateArt();
  }

  async checkStatusAndUpdateArt() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      this.paymentMessage = "Something went wrong.";
      return;
    }
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(
      clientSecret
    );

    switch (paymentIntent.status) {
      case "succeeded":
        this.paymentMessage = "Thank you for your order!";
        this.paymentSucceeded = true;
        break;
      case "processing":
        this.paymentMessage =
          "Thank you for your order! Your payment is processing.";
        this.paymentSucceeded = true;
        break;
      case "requires_payment_method":
        this.paymentMessage =
          "Your payment was not successful, please try again.";
        break;
      default:
        this.paymentMessage = "Something went wrong.";
        break;
    }

    if (this.paymentSucceeded && this.art && paymentIntent) {
      // mark the art as sold and include the customer address and email from the paymentIntent
      const saleDetails: SaleDetails = {
        name: paymentIntent.shipping.name,
        email: paymentIntent.receipt_email,
        street: paymentIntent.shipping.address.line1,
        city: paymentIntent.shipping.address.city,
        state: paymentIntent.shipping.address.state,
        zip: paymentIntent.shipping.address.postal_code,
        saleDate: new Date().toLocaleDateString()
      };

      if (this.art.isReproduction) {
        this.art.reproductionSaleDetails = [saleDetails, ...this.art.reproductionSaleDetails ?? []];
        const documentToUpdate = doc(
          firestore,
          "reproductions",
          this.$route.params.id
        );
  
        await setDoc(documentToUpdate, this.art);
      } else {
        this.art.saleDetails = saleDetails;
        this.art.soldDate = new Date().toLocaleString();
  
        const documentToUpdate = doc(
          firestore,
          "home-images",
          this.$route.params.id
        );
  
        await setDoc(documentToUpdate, this.art);
      }
    }
  }
}
