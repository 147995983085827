










import { Component, Vue } from "vue-property-decorator";
import { firestore } from "../../firebaseInit";
import { doc, getDoc } from "firebase/firestore";

@Component({ components: {} })
export default class About extends Vue {
  private aboutText: string = "";
  private contentDoc = doc(firestore, "page-content", "SSLO9vxO73B5pvjLeUAG");

  async created() {
    const docSnap = await getDoc(this.contentDoc);

    if (docSnap.exists()) {
      const content: any = docSnap.data();
      this.aboutText = content.about;
    }
  }
}
