



























































































































































import { Component, Vue } from "vue-property-decorator";
import { firestore, storage, functions } from "../../firebaseInit";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Art } from "../../models/Art";
import { ref, getDownloadURL } from "firebase/storage";
import { loadStripe, StripeElements } from "@stripe/stripe-js";

@Component({ components: {} })
export default class ArtDetails extends Vue {
  private art: Art | null = null;
  private url: string = "";
  private notFound: boolean = false;
  private state: "view" | "paying" | "shipping" = "view";
  private paymentIntentLoading: boolean = false;
  private stripe!: any;
  private paymentIntent!: any;
  private stripeElements!: StripeElements;
  private paymentErrorMessage: string = "";
  private shipmentDetails: {
    name: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  } = { name: "", email: "", street: "", city: "", state: "", zip: "" };
  private createPaymentIntent = httpsCallable(functions, "createPaymentIntent");

  get priceIsNan() {
    return this.art && isNaN(parseInt(this.art.price?.toString() ?? "", 10));
  }

  async created() {
    this.stripe = await loadStripe(
      "pk_live_51L1gblGdMI5Rj1u0H4phwU7fOKvTN0Y3BtdW4kLtrMKxfu5XLlbcwj6E6aJhyesNaUMqSQjgxi2SoClJrkatMkcZ00EhfR0dCL"
    );
    const galleryRef = doc(firestore, "home-images", this.$route.params.id);
    const gallerySnap = await getDoc(galleryRef);

    const reproductionsRef = doc(firestore, "reproductions", this.$route.params.id);
    const reproductionSnap = await getDoc(reproductionsRef);

    if (gallerySnap.exists()) {
      this.art = gallerySnap.data() as Art;
      this.art.id = gallerySnap.id;
      const path = ref(storage, this.art.storageLocation);
      this.url = await getDownloadURL(path);
    } 
    else if (reproductionSnap.exists()) {
      this.art = reproductionSnap.data() as Art;
      this.art.id = reproductionSnap.id;
      const path = ref(storage, this.art.storageLocation);
      this.url = await getDownloadURL(path);    
    }
    else {
      this.notFound = true;
    }
  }

  async handleClickBuy() {
    this.state = "shipping";
  }

  async submitDetails() {
    if (
      !(
        this.shipmentDetails.name &&
        this.shipmentDetails.email &&
        this.shipmentDetails.street &&
        this.shipmentDetails.city &&
        this.shipmentDetails.state &&
        this.shipmentDetails.zip
      )
    )
      alert(
        "There is an issue with the details you entered, please try again."
      );
    else this.handlePayment();
  }

  async handlePayment() {
    if (!this.art?.price) {
      alert("There has been an issue, please try again.");
      return;
    }
    this.state = "paying";
    this.paymentIntentLoading = true;

    const paymentIntentResponse: any = await this.createPaymentIntent({
      amount: this.art.price * 100,
      description: `ART SOLD: ${this.art.name}, ${new Date().toLocaleString()}`,
      name: this.shipmentDetails.name,
      email: this.shipmentDetails.email,
      street: this.shipmentDetails.street,
      city: this.shipmentDetails.city,
      state: this.shipmentDetails.state,
      zip: this.shipmentDetails.zip,
    }).catch(() => {
      this.paymentIntentLoading = false;
      this.state = "view";
      alert(
        "There has been an error with our payment system. Please try again later."
      );
      return;
    });

    this.paymentIntent = paymentIntentResponse?.data;

    if (this.stripe) {
      this.stripeElements = this.stripe?.elements({
        clientSecret: this.paymentIntent.client_secret,
      });
      const paymentElement = this.stripeElements?.create("payment");
      paymentElement?.mount("#stripe-payment");
    }

    setTimeout(() => {
      this.paymentIntentLoading = false;
    }, 500);
  }

  async submitPayment() {
    if (this.stripe && this.stripeElements) {
      const { error } = await this.stripe?.confirmPayment({
        elements: this.stripeElements,
        confirmParams: {
          // return_url: `http://localhost:8080/payment-confirmed/${this.art?.id}`,
          return_url: `https://art-webapp-812f7.web.app/payment-confirmed/${this.art?.id}`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error.type === "card_error" || error.type === "validation_error") {
        this.paymentErrorMessage = error.message || "";
      } else {
        this.paymentErrorMessage = "An unexpected error occured.";
      }
    }
  }
}
