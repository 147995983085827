import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./components/pages/Home.vue";
import About from "./components/pages/About.vue";
import Contact from "./components/pages/Contact.vue";
import Reproductions from "./components/pages/Reproductions.vue";
import ArtDetails from "./components/pages/ArtDetails.vue";
import PaymentConfirmed from "./components/pages/PaymentConfirmed.vue";
import FourOhFour from "./components/pages/404.vue";
import Admin from "./components/pages/Admin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: `/`,
    component: Home,
  },
  {
    path: `/reproductions`,
    component: Reproductions,
  },
  {
    path: `/about`,
    component: About,
  },
  {
    path: `/contact`,
    component: Contact,
  },
  {
    path: `/admin`,
    component: Admin,
  },
  {
    path: `/art/:id`,
    component: ArtDetails,
  },
  {
    path: `/payment-confirmed/:id`,
    component: PaymentConfirmed,
  },
  {
    path: `*`,
    component: FourOhFour,
  },
];

const Router = new VueRouter({
  mode: `history`,
  base: process.env.BASE_URL,
  routes,
});

export default Router;
