






import { Component, Vue } from "vue-property-decorator";
import ArtShowcase from "../ArtShowcase.vue";
import { firestore } from "../../firebaseInit";
import { collection, getDocs } from "firebase/firestore";
import { Art } from "../../models/Art";

@Component({
  components: {
    ArtShowcase,
  },
})
export default class Home extends Vue {
  private artArray: any[] = [];

  async created() {
    const imagesRef = collection(firestore, "home-images");
    const collectionSnap = await getDocs(imagesRef);

    let artArray: Art[] = [];
    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id };
      artArray.push(art as Art);
    });

    let shuffle = (array: any[]): Art[] => {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    };

    let forSaleArt = artArray.filter(
      (art) => (art.price ?? 0) > 0 && !art.soldDate
    );

    let isNotNumber = (art: Art) =>
      isNaN(parseInt(art.price?.toString() ?? "", 10));
    let soldArt = artArray.filter(
      (art) => isNotNumber(art) || (art.price ?? 0) <= 0 || art.soldDate
    );

    this.artArray = [...shuffle(forSaleArt), ...shuffle(soldArt)].slice(0, 100);
  }
}
